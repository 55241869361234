/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import React, {ThHTMLAttributes} from 'react'
import {
    ColumnDef,
    ColumnSort,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    Row,
    RowData,
    RowSelectionState,
    SortDirection,
    SortingState,
    Updater,
    useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import {ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon} from '@heroicons/react/24/solid'
import {RankingInfo} from '@tanstack/match-sorter-utils'
import {Loader} from 'rsuite'

declare module '@tanstack/table-core' {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    interface FilterMeta {
        itemRank: RankingInfo
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    interface ColumnMeta<TData extends RowData, TValue> {
        align: ThHTMLAttributes<any>['align']
    }
}

export function urlSearchParamsToSortingState(params: URLSearchParams): SortingState {
    const tableSort: SortingState = []
    params.forEach((value, key) => {
        switch (value) {
            case 'asc':
                tableSort.push({id: key, desc: false})
                break
            case 'desc':
                tableSort.push({id: key, desc: true})
        }
    })
    return tableSort
}

export function sortingStateToObject(sorting?: SortingState): Record<string, SortDirection> {
    return (
        sorting?.reduce((acc: Record<string, SortDirection>, curr: ColumnSort) => {
            acc[curr.id] = curr.desc ? 'desc' : 'asc'
            return acc
        }, {}) ?? {}
    )
}

export interface DataTableProps<T> {
    data: T[]
    columns: ColumnDef<T, any>[]
    spaced?: boolean
    filter?: string
    enableRowSelection?: boolean
    rowSelection?: Record<number, boolean>
    onRowSelectionChanged?: (selection: RowSelectionState) => void
    manualPagination?: boolean
    onSortingChanged?: (sortingState: SortingState) => void
    manualSorting?: boolean
    sorting?: SortingState
    onRowClicked?: (row: Row<T>) => void
    renderSubComponent?: (props: {row: Row<T>}) => React.ReactNode
    getRowCanExpand?: (row: Row<T>) => boolean
    compact?: boolean
    highlightOnHover?: boolean
    getRowStyle?: (row: T) => any
    styleClasses?: {
        table?: string
        thead?: string
        headerRow?: string
        th?: string
        tbody?: string
        tr?: string
        subRow?: string
        td?: string
        tfoot?: string
        footerRow?: string
    }
    loading?: boolean
    spinnerWhileLoading?: boolean
}

function DataTable<T>({
    data,
    columns,
    filter,
    enableRowSelection,
    onRowSelectionChanged,
    onRowClicked,
    rowSelection,
    manualPagination,
    onSortingChanged,
    manualSorting,
    sorting,
    renderSubComponent,
    getRowCanExpand,
    compact,
    highlightOnHover,
    spaced,
    getRowStyle = () => ({}),
    styleClasses,
    loading,
    spinnerWhileLoading,
}: DataTableProps<T>) {
    const [_sorting, _setSorting] = React.useState<SortingState>([])
    function handleSortingChange(changeState: Updater<SortingState>) {
        const newState = typeof changeState === 'function' ? changeState(sorting ?? _sorting ?? {}) : changeState

        if (onSortingChanged && sorting) {
            onSortingChanged(newState)
        } else {
            _setSorting(newState)
            onSortingChanged?.(newState)
        }
    }

    const [_rowSelection, _setRowSelection] = React.useState<RowSelectionState>({})
    // TODO: Have yet to get a 100% working generic controlled/uncontrolled onChange handler for these variables so far.
    function handleSelectionChange(changeState: Updater<RowSelectionState>) {
        const newState =
            typeof changeState === 'function' ? changeState(rowSelection ?? _rowSelection ?? {}) : changeState

        if (onRowSelectionChanged && rowSelection) {
            onRowSelectionChanged(newState)
        } else {
            _setRowSelection(newState)
            onRowSelectionChanged?.(newState)
        }
    }

    function handleOnRowClick(e: any, row: Row<T>) {
        if (enableRowSelection) {
            row.getToggleSelectedHandler()(e)
        }
        onRowClicked?.(row)
    }

    const table = useReactTable<T>({
        data,
        columns,
        enableRowSelection,
        manualPagination,
        manualSorting,
        getRowCanExpand,
        enableMultiSort: true,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting: manualSorting ? sorting : _sorting,
            rowSelection: rowSelection ?? _rowSelection,
            globalFilter: filter,
        },
        onRowSelectionChange: handleSelectionChange,
        onSortingChange: handleSortingChange,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    if (loading && spinnerWhileLoading) {
        return (
            <div className='my-3 flex justify-center'>
                <Loader content='Loading...' />
            </div>
        )
    }

    return (
        <table className={classNames(styleClasses?.table, 'min-w-full ', loading)}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className={classNames(styleClasses?.headerRow)}>
                        {headerGroup.headers.map((header, index) => (
                            <th
                                key={header.id}
                                align={(header.column.columnDef.meta as any)?.align}
                                className={classNames(
                                    styleClasses?.th,
                                    header.column.getCanSort() && 'cursor-pointer select-none',
                                    index === 0 ? (spaced ? 'pl-7 pr-3' : 'pr-3 pl-4 sm:pl-0') : 'px-3 ',
                                    index === headerGroup.headers.length - 1 ? (spaced ? 'pr-7' : 'pr-0') : '',
                                    'py-3.5 font-semibold text-zinc-900',
                                )}
                            >
                                <div
                                    className='inline-flex items-center space-x-2'
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    {{
                                        asc: <ChevronUpIcon className='h-4 w-4 flex-shrink-0 text-icon' />,
                                        desc: <ChevronDownIcon className='h-4 w-4 flex-shrink-0 text-icon' />,
                                    }[header.column.getIsSorted() as string] ??
                                        (header.column.getCanSort() && (
                                            <ChevronUpDownIcon className='h-4 w-4 flex-shrink-0 text-icon' />
                                        ))}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className={classNames(styleClasses?.tbody)}>
                {table.getRowModel().rows.map((row) => (
                    <React.Fragment key={row.id}>
                        <tr
                            className={classNames(styleClasses?.tr, getRowStyle(row.original), {
                                'bg-gray-100': row.getIsExpanded(),
                                'hover:bg-gray-50': highlightOnHover,
                            })}
                            onClick={(e) => handleOnRowClick(e, row)}
                        >
                            {row.getVisibleCells().map((cell, index) => (
                                <td
                                    key={cell.id}
                                    className={classNames(
                                        styleClasses?.td,
                                        compact ? 'py-2' : 'py-4',
                                        index === 0 ? (spaced ? 'pl-7 pr-3' : 'pr-3 pl-4 sm:pl-0') : ' px-3 ',
                                        index === row.getVisibleCells().length - 1 ? (spaced ? 'pr-7' : 'pr-0') : '',
                                        {'cursor-pointer': enableRowSelection},
                                    )}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                        {renderSubComponent && row.getIsExpanded() && (
                            <tr className={classNames(styleClasses?.subRow)}>
                                {/* 2nd row is a custom 1 cell row */}
                                <td colSpan={row.getVisibleCells().length}>{renderSubComponent({row})}</td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
            <tfoot className={classNames(styleClasses?.tfoot, '!border-b-0 !border-t-0')}>
                {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id} className={classNames(styleClasses?.footerRow)}>
                        {footerGroup.headers.map((header) => (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.footer, header.getContext())}
                            </th>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
    )
}

export default DataTable
