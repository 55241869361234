/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import {Navigate, NavLink, Outlet} from 'react-router-dom'
import {LOGIN_PAGES} from '@/constants/pages'
import {useAuthState} from '@/auth/authContext'
import {Fragment, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {Bars3BottomLeftIcon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {MagnifyingGlassIcon} from '@heroicons/react/20/solid'
import Sidebar, {SidebarProps} from '@/components/Sidebar'
import {NavMenuItem} from '@/types'
import classNames from 'classnames'
import {API} from '@/constants/api'

interface LayoutProps {
    sidebar: SidebarProps
    profileDropdownItems: NavMenuItem[]
}

function Horizontal({sidebar, profileDropdownItems}: LayoutProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [authState] = useAuthState()

    if (!authState.isAuthenticated) {
        return <Navigate to={LOGIN_PAGES.LOGIN.path} />
    }

    const filterByRole = (acc: NavMenuItem[], item: NavMenuItem) => {
        // if (userCanAccessRoute(authState.userInfo, item.requiredRoles)) {
        acc.push({
            ...item,
            children: item.children ? item.children.reduce(filterByRole, []) : undefined,
        })
        // }
        return acc
    }
    const navigation = {
        ...sidebar.navigation,
        items: sidebar.navigation.items.reduce(filterByRole, []),
    }

    return (
        <div className='min-h-screen bg-gray-100'>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>

                    <div className='fixed inset-0 z-40 flex'>
                        <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'
                        >
                            <Dialog.Panel className='darkTheme relative flex w-full max-w-xs flex-1 flex-col bg-midnight pt-5 pb-4'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-in-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in-out duration-300'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                        <button
                                            type='button'
                                            className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className='sr-only'>Close sidebar</span>
                                            <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <Sidebar {...sidebar} navigation={navigation} />
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className='w-14 flex-shrink-0' aria-hidden='true'>
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className='darkTheme hidden bg-midnight md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
                <Sidebar {...sidebar} navigation={navigation} />
            </div>
            <div className='flex flex-1 flex-col md:pl-64'>
                <div className='sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow'>
                    <button
                        type='button'
                        className='border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent md:hidden'
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className='sr-only'>Open sidebar</span>
                        <Bars3BottomLeftIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                    <div className='flex flex-1 justify-between px-4'>
                        <div className='flex flex-1'>
                            <form className='flex w-full md:ml-0' action='#' method='GET'>
                                <label htmlFor='search-field' className='sr-only'>
                                    Search
                                </label>
                                <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                                    <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center'>
                                        <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
                                    </div>
                                    <input
                                        id='search-field'
                                        className='block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0'
                                        placeholder='Search'
                                        type='search'
                                        name='search'
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='ml-4 flex items-center md:ml-6'>
                            <button
                                type='button'
                                className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2'
                            >
                                <span className='sr-only'>View notifications</span>
                                <BellIcon className='h-6 w-6' aria-hidden='true' />
                            </button>

                            {/* Profile dropdown */}
                            <Menu as='div' className='relative ml-3'>
                                <div>
                                    <Menu.Button className='flex max-w-xs items-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2'>
                                        <span className='sr-only'>Open user menu</span>
                                        {authState.userInfo && (
                                            <img
                                                className='h-8 w-8 rounded-full'
                                                src={API.APP.USER.PROFILE.AVATAR.get(authState.userInfo.email)}
                                                alt=''
                                            />
                                        )}
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                >
                                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                        <Menu.Item key='1asd'>
                                            <div className='px-4 py-2'>
                                                <div className='block text-gray-900'>
                                                    {authState.userInfo?.fullName}
                                                </div>
                                                <div className='block text-sm text-gray-400'>
                                                    {authState.userInfo?.email}
                                                </div>
                                            </div>
                                        </Menu.Item>
                                        <hr />
                                        {profileDropdownItems.map((item) => (
                                            <Menu.Item key={item.displayName}>
                                                {({active}) => (
                                                    <NavLink
                                                        to={item.path}
                                                        className={classNames(
                                                            {
                                                                'bg-gray-100': active,
                                                            },
                                                            'block px-4 py-2 text-gray-700',
                                                        )}
                                                    >
                                                        {item.displayName}
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <main>
                    <div className='prose mx-auto py-6 px-8 lg:max-w-[95vw] 3xl:max-w-[80vw]'>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Horizontal
